import React from "react";
import { IRoute } from "../../routing/types";
import SidebarContainer from "./SidebarContainer";
interface ISidebar {
    onClickListItem?: (route: IRoute) => void
    onClickMenu?: (isOpen: boolean) => void
}

const Sidebar: React.FC<ISidebar> = ({ onClickListItem, onClickMenu }) => {
    return (
        <>
            <SidebarContainer
                onClickMenu={onClickMenu}
                onClickListItem={onClickListItem}
            />
        </>
    )
}

export default Sidebar;
