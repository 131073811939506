import {CartAction, ItemAction} from "../actions/types";
import {EDIT_ITEM_IN_CARRELLO, INSERT_IN_CART, RIMUOVI_ITEM_DA_CARRELLO,REMOVE_ALL_ITEM_IN_CART} from "../actions/cart";
import {itemI, itemInCart} from "../../utils/model_types";
import {findInstallazioneSelezionata, findMisuraSelezionata, findTipoInstallazioneSelezionata} from "../../utils/utils";
import _ from 'lodash';

export interface CartState {
    cart: itemInCart[]
}

const initialState: CartState = {
    cart: []
};

const cartReducer = (state = initialState, action: CartAction | ItemAction): CartState => {

    const findItemIndexInCart = (item: itemI, codiceProdotto: string, payload: any) => {

        const lunghezzaProdotto = findMisuraSelezionata(item)?.lunghezza;
        const installazioneSelezionata = findInstallazioneSelezionata(item)?.id;
        const tipoInstallazione = payload.tipiInstallazione && findTipoInstallazioneSelezionata(payload.tipiInstallazione);

        // @ts-ignore
        return state.cart.findIndex((itemInCart: itemInCart) => itemInCart.item.misure.length ?
            findMisuraSelezionata(itemInCart.item)?.lunghezza === lunghezzaProdotto &&
            item.idProdotto === itemInCart.item.idProdotto
            : itemInCart.item.installazioni ? findInstallazioneSelezionata(itemInCart.item)?.id === installazioneSelezionata
                && item.idProdotto === itemInCart.item.idProdotto : tipoInstallazione ? findTipoInstallazioneSelezionata(itemInCart.tipiInstallazione)?.idTipoInstallazione === tipoInstallazione.idTipoInstallazione  : item.idProdotto === itemInCart.item.idProdotto)
    };


    // @ts-ignore
    switch (action.type) {
        case RIMUOVI_ITEM_DA_CARRELLO: {
            // @ts-ignore
            const index = findItemIndexInCart(action.payload, action.payload.codiceProdotto,action.payload);
            return {
                ...state,
                cart: [...state.cart.filter((_, i) => i !== index)]
            }
        }
        case EDIT_ITEM_IN_CARRELLO: {
            //@ts-ignore
            const itemToEdit: itemInCart = action.payload;
            // @ts-ignore
            const {codiceProdotto} = itemToEdit.item;
            const {cart} = state;
            const index = findItemIndexInCart(itemToEdit.item, codiceProdotto,itemToEdit);
            cart[index] = itemToEdit;
            return {
                ...state,
                cart: [...cart]
            }
        }
        case INSERT_IN_CART: {
            //@ts-ignore
            const newItem: itemInCart = _.cloneDeep(action.payload);
            //inserire nel localStorage [...state.cart, newItem]
            const cart = _.cloneDeep(state.cart);
            const index = findItemIndexInCart(newItem.item, newItem.item.codiceProdotto,newItem);
            //@ts-ignore
            if(index !== -1) cart[index].quantita = parseInt(cart[index].quantita) + parseInt(newItem.quantita)
            else cart.push(newItem);

            return {
                ...state,
                cart
            }
        }

        case REMOVE_ALL_ITEM_IN_CART: {
            let cart:any = [];
            return  {
                ...state,
                cart
            }
        }
        default:
            return state;
    }
}

export default cartReducer;
