import {CartAction} from "../actions/types";
import {
    EDIT_ITEM_IN_CARRELLO,
    INSERT_IN_CART,
    REMOVE_ALL_ITEM_IN_CART,
    RIMUOVI_ITEM_DA_CARRELLO
} from "../actions/cart";
import {itemI, itemInCart} from "../../utils/model_types";

export const insertItemIntoCart = (item: itemInCart): CartAction => {
    return {
        type: INSERT_IN_CART,
        payload: item
    };
};

export const editItemInCarrello = (item: itemInCart): CartAction => {
    return {
        type: EDIT_ITEM_IN_CARRELLO,
        payload: item
    };
};

export const rimuoviItemDaCarrello = (item: itemI) => {
    return {
        type: RIMUOVI_ITEM_DA_CARRELLO,
        payload: item
    }
};

export const clearCarrello = () => {
  return {
      type: REMOVE_ALL_ITEM_IN_CART,
  }
};
