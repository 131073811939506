import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { IRoute } from '../../routing/types';
import { getRouteItems } from '../../routing/utils';
import { RootState } from '../../store/reducers';
import { eventBusActionOpenCloseMenu, eventBusAddRemoveOpenCloseMenu } from '../../utils/event-bus/event-bus.actions';
import { breakPointers, getClassesWithPrefix } from '../../utils/utils';
import SidebarList from './components/SidebarList/SidebarList';
import useResize from './hooks/useResize';
import './Sidebar.css'

const sidebarClasess = getClassesWithPrefix('sidebar')

type Props = {
    onClickListItem?: (route: IRoute) => void
    onClickMenu?: (isOpen: boolean) => void
}

const SidebarContainer: React.FC<Props> = ({ onClickMenu, onClickListItem }) => {
    const { innerWidth } = useResize()
    const [isOpenMenu, setIsOpenMenu] = useState(!(breakPointers.tablet >= innerWidth))
    const storeUser = useSelector((state: RootState) => state.auth.user)
    const availableRoutes = getRouteItems(storeUser)

    const sidebarRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const checkCurrentItem = (e: MouseEvent) => {
            const clickedElTarget = e.target as HTMLElement
            const currentRef = sidebarRef.current
            let isMenuHeader = false
            if (clickedElTarget && clickedElTarget.className && clickedElTarget.className.includes) {
                isMenuHeader = clickedElTarget.className.includes('menu-container') || clickedElTarget.className.includes('fa-times')
            }
            if ((currentRef && !currentRef.contains(clickedElTarget) && !isMenuHeader) && (breakPointers.phone >= innerWidth)) {
                eventBusActionOpenCloseMenu(false)
            }
        }

        document.addEventListener('click', checkCurrentItem)

        return () => {
            document.removeEventListener('click', checkCurrentItem)
        }
    }, [innerWidth])

    useEffect(() => {
        const evtOpenCloseMenu = eventBusAddRemoveOpenCloseMenu(setIsOpenMenu)

        return () => {
            evtOpenCloseMenu.remove()
        }
    }, [isOpenMenu])

    const handlerClickListItem = useCallback((route: IRoute) => {
        if (onClickListItem) onClickListItem(route)
        if (breakPointers.phone >= innerWidth) eventBusActionOpenCloseMenu(false)
    }, [innerWidth, onClickListItem])

    return (
        <div
            ref={sidebarRef}
            className={`${sidebarClasess('container', `size-${isOpenMenu ? 'md' : 'sm'}`)}`}
        >
            <div className={sidebarClasess('list')}>
                <SidebarList
                    handlerClickListItem={handlerClickListItem}
                    routes={availableRoutes}
                />
            </div>
        </div>
    )
}

export default SidebarContainer