import React, {FormEvent, useState} from 'react';
import {useDispatch} from "react-redux";

import {auth} from "../../store/actionCreators/auth";
import {US} from "../../store/actions/general";

import logo from '../../assets/img/Grouplogo.svg';

import './Login.css'
import {useHistory} from "react-router";
import {toggleToast} from "../../store/actionCreators/general";


const Login = () => {
    const dispatch = useDispatch();
  const history = useHistory();

  /**
     * Da commentare quando si committa il codice
     */
    // const users = ["user_sogei", "2nd_user_sogei", "user_sogei_privilegi", "user_tim_back_office", "user_tim_back_office_privilegi", "progettista"];
    // const [username, setUsername] = useState(users[5]);
    // const [password, setPassword] = useState(users[5]);

    /**
     * Da ripristinare quando si committa il codice
     */
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const authHandler = (e: FormEvent) => {
        if(username && password){
          //TODO - collegaure il metodo auth corretto
          e.preventDefault();
          //const f = auth(dispatch, {username, password, role: [US]});
          dispatch(auth({username, password, role: [US]}))
        } else {
          dispatch(toggleToast("error", "Inserire le credenziali"))
        }

    };


    return (
        <div id="login-page">
            <div id="left-content">
                <img src={logo} alt="Logo Catalogo Elettronico"/>
            </div>
            <div id="right-content">
                <div id="form">
                    <div id="form-header">
                        <img src={logo} alt="Logo Catalogo Elettronico"/>
                        <h3 className="text-uppercase color-giallo font-weight-bold mb-0 ml-4">catalogo elettronico</h3>
                    </div>
                    <h1 className="color-viola text-center font-weight-bold mt-3 mb-0">Login</h1>
                    <form className="mt-4" onSubmit={authHandler}>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input type="text"
                                   value={username}
                                   onChange={e => setUsername(e.target.value)}
                                   className="form-control form-control-sm"
                                   id="username"
                                   placeholder="Username"
                                   aria-describedby="username"/>
                            {/*<small id="emailHelp" className="form-text text-danger">We'll never share your email with anyone else.</small>*/}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password"
                                   value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   className="form-control form-control-sm"
                                   placeholder="Password"
                                   id="password"/>
                        </div>
                      <div className="text-center">
                        <span className="passwordReset" onClick={() => history.push('/forgotPassword')}>Password dimenticata?</span>
                      </div>
                        <button type="submit" className="btn btn-block btn-viola mt-4">Accedi</button>
                    </form>
                </div>
            </div>
        </div>
    );
};


export default Login;
