import {
    TOGGLE_LOADING,
    TOGGLE_MENU,
    SELECTED_PAGE
} from "../actions/general";

import { toast } from "react-toastify";
import { GeneralAction } from "../actions/types";

export const toggleSpinner = (): GeneralAction => {
    return {
        type: TOGGLE_LOADING,
        payload: {}
    }
};

export const toggleMenu = (): GeneralAction => {
    return {
        type: TOGGLE_MENU,
        payload: {}
    }
};

export const toggleToast = (tipo: "success" | "error", messaggio: string): GeneralAction => {
    if (tipo === 'success') {
        toast.success(messaggio, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
        })
    }
    else {
        toast.error(messaggio, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true
        });
    }

    return {
        type: '',
        payload: {}
    }
};

export const setSelectedPage = (page: string) => {
    return {
        type: SELECTED_PAGE,
        payload: {
            message: page
        }
    }
}
