import {setSelectedPage, toggleSpinner, toggleToast} from "./general";

import {AUTH_FAIL, AUTH_LOGOUT, AUTH_START, AUTH_SUCCESS} from '../actions/auth';
import {IUser} from "../../routing/types";
import {Action, AuthAction, GeneralAction} from "../actions/types";
import {Dispatch} from "react";
import axiosInstance from "../../api/interceptor";
import {parseJwt} from "../../routing/utils";

export const authStart = (): AuthAction => {
    return {
        type: AUTH_START,
        payload: {
            isLoading: true
        }
    };
};

/**
 *
 * @param user
 * @returns {{type: string, error: null, user: *}}
 */
export const authSuccess = (user: IUser): AuthAction => {
    return {
        type: AUTH_SUCCESS,
        payload: {
            user: user
        }
    };
};

export const authFail = (error: boolean): AuthAction => {
    return {
        type: AUTH_FAIL,
        payload: {
            error: error
        }
    };
};

export const logout = (): AuthAction => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('user');
    localStorage.removeItem('username');
    localStorage.removeItem('path');
    return {
        type: AUTH_LOGOUT,
        payload: {
            authorities: [],
            authCheckEnd: false,
            error: false,
            isLoading: false,
            token: undefined,
            user: undefined
        }
    };
};

export const checkAuthTimeout = (dispatch: Dispatch<AuthAction>, expirationTime: number) => {
    setTimeout(() => {
        dispatch(logout());
    }, expirationTime * 1000);
};


// TODO: da cancellare quando ci saranno le chiamate BE
/*
export const auth = (user: IUser) => {
    return (dispatch: Dispatch<GeneralAction | AuthAction>) => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        const oreExpiration = 12;
        const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('expiration', expirationDate.toString());
        localStorage.setItem('token', 'token');
        dispatch(toggleSpinner());
        dispatch(authSuccess(user));
    }
};
*/

// TODO: da tenere commentata finché non ci saranno le chiamate BE
export const auth = ({username, password}: any) => {
    /* const { REACT_APP_KEYCLOAK_URL } = process.env;*/
    return (dispatch: Dispatch<GeneralAction | AuthAction>) => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        axiosInstance.post('auth/signin', {username, password}).then(response => {
            const oreExpiration = 12;
            const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
            localStorage.setItem('expiration', expirationDate.toString());
            localStorage.setItem('token', response.data.accessToken);
            dispatch(toggleSpinner());
            const role = parseJwt(response.data.accessToken)
            dispatch(authSuccess(role));
        }).catch(error => {
            dispatch(toggleSpinner())
            dispatch(toggleToast('error', error.messaggio || 'Verifica le credenziali di accesso'))
        })

    };
};

export const authCheckState = (dispatch: Dispatch<Action>) => {
    authStart();

    const token = localStorage.getItem('token');
    if (!token) {
        dispatch(logout());
    } else {
        const expirationString = localStorage.getItem("expiration");
        if (!expirationString) return;

        const expirationDate = new Date(expirationString);
        if (expirationDate.getTime() <= new Date().getTime()) {
            dispatch(logout());
        } else {
            const selectedPage = localStorage.getItem('selected-page');
            dispatch(setSelectedPage(selectedPage || ''));
            dispatch(authSuccess(parseJwt(token)));
            checkAuthTimeout(dispatch, (expirationDate.getTime() - new Date().getTime()) / 1000);
        }
    }

};
