import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";

import Layout from "../Layout/Layout";
import { getRoutes } from '../../routing/utils';
import { RootState } from '../../store/reducers';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { authCheckState } from "../../store/actionCreators/auth";

const App = () => {
    const storeIsLoading = useSelector((state: RootState) => state.general.loading)
    const storeUser = useSelector((state: RootState) => state.auth.user);

    const dispatch = useDispatch();

    useEffect(() => {
        authCheckState(dispatch);
    }, []);


    return (
        <>
            <CssBaseline/>
            {
                !storeUser ?
                    getRoutes(storeUser) :
                    <div id="app-container" className={[storeIsLoading && 'no-event'].filter(Boolean).join(' ')}>
                        <Layout/>
                    </div>
            }
            <ToastContainer autoClose={5000}/>
            {storeIsLoading && <LoadingSpinner/>}
        </>
    );
}

export default App;
