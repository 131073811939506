import { combineReducers } from "redux";
import authReducer from "./auth"
import generalReducer from "./general"
import cartReducer from "./cart";
import ordinireducer from "./ordini";

export const rootReducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    cart: cartReducer,
    ordini: ordinireducer
});

export type RootState = ReturnType<typeof rootReducer>
