import {OrdiniAction} from "../actions/types";
import {orderI} from "../../utils/model_types";
import {POPOLA_ORDINI, EDIT_ORDINE} from "../actions/ordini";
import _ from 'lodash';

export interface ordiniState {
    ordini: orderI[]
}

const initialState: ordiniState = {
    ordini: []
}

const ordinireducer = (state = initialState, action: OrdiniAction): ordiniState => {
    switch (action.type) {
        case POPOLA_ORDINI: {
            const ordini = _.cloneDeep(action.payload);
            return {
                ...state,
                ordini
            }
        }
        case EDIT_ORDINE: {
            const ordini = _.cloneDeep(action.payload);
            return {
                ...state,
                ordini
            }
        }
        default:
            return state;
    }
}

export default ordinireducer;
