import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header/Header";
import Container from "@material-ui/core/Container";
import {Fab, makeStyles} from "@material-ui/core";
import {scrollTopElement} from "../../utils/utils";
import ArrowUpwardIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {useHistory} from "react-router";
import axiosInstance from "../../api/interceptor";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {useDispatch} from "react-redux";
import {logout} from "../../store/actionCreators/auth";


const ForgotPassword = () => {

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },

    appBarSpacer: theme.mixins.toolbar,

    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },

    container: {
      paddingTop: '16px',
      paddingBottom: theme.spacing(4),
      paddingLeft:'100px',
      paddingRight:'100px'
    },
    fab: {
      position: 'fixed',
      bottom: '90px',
      right: '40px',
      transition: '.3s display',
      display: 'none',
      '&.show': {
        display: 'block'
      },
      opacity: 0.4
    }
  }));
  const classes = useStyles();
  const mainRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    // @ts-ignore
    mainRef.current.onscroll = () => {
      // @ts-ignore
      mainRef.current.scrollTop > 250 ? setShow(true) : setShow(false)
    };
  }, []);

  const confirmEmail = () => {
    dispatch(toggleSpinner());
    let data = {email: email};
    axiosInstance.put('auth/resetPassword', null, {params: data}).then(() => {
      dispatch(toggleToast("success", "Email inserita correttamente, controllare la casella di posta elettronica"));
      dispatch(logout());
      history.push('/login');
    })
      .catch(error => dispatch(toggleToast("error", error.messaggio)))
      .finally(() => dispatch(toggleSpinner()));
  };

  return (
    <div className={classes.root}>
      <Header/>
      <main className={classes.content} ref={mainRef}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="xl" className={classes.container}>
          <div className="form">
            <h1 className="color-viola text-center font-weight-bold mt-3 mb-0">Password dimenticata </h1>
            <form className="mt-4">
              <div className="form-group">
                <label htmlFor="username">Email</label>
                <input type="email"
                       className="form-control form-control-sm"
                       value={email}
                       onChange={e => setEmail(e.target.value)}
                       id="email"
                       placeholder="Email"
                       aria-describedby="email"/>
                {/*<small id="emailHelp" className="form-text text-danger">We'll never share your email with anyone else.</small>*/}
              </div>
              <button type="button"
                      onClick={confirmEmail}
                      className="btn btn-block btn-viola mt-4"
                      disabled={!email}>Conferma
              </button>
            </form>
          </div>
        </Container>
      </main>
      <Fab color="primary"
           onClick={() => scrollTopElement("main")}
           className={[classes.fab, show && 'show'].filter(Boolean).join(' ')}
           aria-label="add">
        <ArrowUpwardIcon/>
      </Fab>
    </div>
  )
};

export default ForgotPassword
