import React, { useCallback, useEffect, useState } from 'react'
import { IRoute } from '../../../../routing/types';
import SidebarListItem from './SidebarListItem';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SELECTED_PAGE } from '../../../../store/actions/general';
import './SidebarList.css'
import {RootState} from "../../../../store/reducers";

type Props = {
    routes: IRoute[]
    handlerClickListItem?: (route: IRoute) => void
}

const SidebarList: React.FC<Props> = ({ routes, handlerClickListItem }) => {
    const [selectedItem, setSelectedItem] = useState('')
    const history = useHistory();
    const dispatch = useDispatch();
    const storeUser = useSelector((state: RootState) => state.auth.user);

    const routeTo = useCallback((route: IRoute) => {
        if (history.location.pathname !== route.path) {
            history.push(route.path);
            dispatch({ type: SELECTED_PAGE, payload: { message: route.name } });
            localStorage.setItem('selected-page', route.name);
        }
    }, [dispatch, history])

    useEffect(() => {
        setSelectedItem(localStorage.getItem('path') || '')
        const checkCurrentItem = (e: MouseEvent) => {
            if (selectedItem !== localStorage.getItem('path')) {
                setSelectedItem('')
            }
        }
        document.addEventListener('click', checkCurrentItem)

        return () => {
            document.removeEventListener('click', checkCurrentItem)
        }
    }, [selectedItem])

    const handlerClickItem = useCallback((route: IRoute) => {
        routeTo(route)
        setSelectedItem(route.path)
        if (handlerClickListItem) handlerClickListItem(route)
    }, [routeTo, handlerClickListItem])

    return (
        <ul>
            {
                storeUser && !storeUser.firstAccess && routes.filter(route => route.showInSidebar).map(route =>
                    <SidebarListItem
                        key={route.name}
                        isSelected={selectedItem === route.path}
                        handlerClick={handlerClickItem}
                        route={route}
                    />
                )
            }
        </ul>
    )
}

export default SidebarList
