import EventBus from "./event-bus";

export const EVENT_BUS_LIST = { openCloseMenu: 'openCloseMenu', sortGrid: 'sortGrid' }

export const eventBusActionOpenCloseMenu = (isOpen: boolean) => {
    const openCloseMenu = EventBus.action(EVENT_BUS_LIST.openCloseMenu, isOpen);
    EventBus.dispatch(openCloseMenu)
}

export const eventBusAddRemoveOpenCloseMenu = (cb: (isOpen: boolean) => void) => {

    EventBus.add(EVENT_BUS_LIST.openCloseMenu, cb)

    return {
        remove: () => EventBus.remove(EVENT_BUS_LIST.openCloseMenu, cb)
    }
}

type PropSortGrid = {
    isAscending: boolean
    columnId: string
}

export const eventBusActionSortGrid = ({ columnId, isAscending }: PropSortGrid) => {
    const sortGrid = EventBus.action(EVENT_BUS_LIST.sortGrid, { columnId, isAscending });
    EventBus.dispatch(sortGrid)
}

export const eventBusAddRemoveSortGrid = (cb: ({ columnId, isAscending }: PropSortGrid) => void) => {
    EventBus.add(EVENT_BUS_LIST.sortGrid, cb)

    return {
        remove: () => EventBus.remove(EVENT_BUS_LIST.sortGrid, cb)
    }
}