//import {AddBox, Dashboard, FormatListBulleted, People} from '@material-ui/icons';
import {Euro, FormatListBulleted, People, BarChart} from '@material-ui/icons';
import {Fragment, lazy} from 'react';
import {IRoute} from './types';
//import {US, USP, UTBO, UTBOP, PROGETTISTA} from "../store/actions/general";
import Login from '../pages/Login/Login';
//import PersonIcon from '@material-ui/icons/Person';
//import BuildIcon from '@material-ui/icons/Build';
import {US, USP} from "../store/actions/general";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
const UnimplementedRoute = lazy(() => import('../pages/Unimplemented/Unimplemented'));
const Catalogo = lazy(() => import('../pages/Catalogo/Catalogo'));
const ElencoOrdini = lazy(() => import('../pages/ElencoOrdini/ElencoOrdini'));
const GestioneUtenti = lazy(() => import('../pages/GestioneUtenti/GestioneUtenti'));
const Report = lazy(() => import('../pages/Report/Report'));
const CreateUser = lazy(() => import('../pages/CreateUser/CreateUser'));
const Carrello = lazy(() => import('../pages/CarrelloPage/CarrelloPage'));

/*
 TIPOLOGIE DI ACCESSO
    0: visibile a tutti gli utenti (non autenticati, autenticati e autenticati con privilegi)
    1: visibile solo agli utenti non autenticati
    2: visibile a tutti utenti autenticati
    3: visibile solo agli utenti autenticati con un determinato ruolo
        In caso di accesso di tipo 3 bisogna indicare nell'array "roles" quali possono accedere alla pagina.
        Il tipo di dato da inserire all'interno di "roles" può essere di qualsiasi tipo (numero, stringa ecc.)

 Per impostare un path nei route senza farlo comparire nei link basta mettere il name come stringa vuota
 */

/* ========== Components attached to routes ==========
        ATTENZIONE! Usare sempre il lazy loading
*/


// Array contente tutte le pagine con relative configurazioni

const defaultRoute: IRoute = {
    path: "/",
    name: "",
    isExternalPage: false,
    logo: {},
    component: Fragment,
    access: [2],
    private: false,
    roles: [],
    style: {},
    showInSidebar: true
};

export const routes: IRoute[] = [
    {
        ...defaultRoute,
        path: "/login",
        component: Login,
        access: [1],
        roles: []
    },
    {
        ...defaultRoute,
        name: "Page not found",
        path: "/404",
        component: UnimplementedRoute,
        access: [0],
        roles: [],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Catalogo",
        path: "/catalogo",
        component: Catalogo,
        access: [3],
        roles: [US],
        logo: {icon: Euro}
    },
    {
        ...defaultRoute,
        name: "Elenco Ordini",
        path: "/elenco-ordini",
        component: ElencoOrdini,
        access: [2],
        logo: {icon: FormatListBulleted}
    },
    {
        ...defaultRoute,
        name: "Gestione Utenti",
        path: "/gestione-utenti",
        component: GestioneUtenti,
        access: [3],
        roles: [USP],
        logo: {icon: People}
    },
    {
        ...defaultRoute,
        name: "Report",
        path: "/report",
        component: Report,
        access: [2],
        roles: [USP],
        logo: {icon: BarChart}
    },
    {
        ...defaultRoute,
        name: "Crea Utente",
        path: "/crea-utente",
        component: CreateUser,
        access: [2],
        roles: [USP],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Carrello",
        path: "/carrello",
        component: Carrello,
        access: [2],
        roles: [],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Reset password",
        path: "/resetPassword",
        component: ResetPassword,
        access: [2],
        roles: [],
        showInSidebar: false
    },
    {
        ...defaultRoute,
        name: "Forgot Password",
        path: "/forgotPassword",
        component: ForgotPassword,
        access: [1],
        roles: [],
        showInSidebar: false
    },

];
