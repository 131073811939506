import React, {Children, memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import {itemInCart} from "../../../utils/model_types";
import {findMisuraSelezionata, getTotaleItemInCart} from "../../../utils/utils";
import {convertEuro} from "../../../routing/utils";
import './AnteprimaCarrello.css';
import {useHistory} from "react-router-dom";
import { clearCarrello } from "../../../store/actionCreators/cart";
import etichetta from "../../../assets/img/etichetta.png";

function AnteprimaCarrello() {

    const dispatch = useDispatch();
    const history = useHistory();
    const cart = useSelector((state: RootState) => state.cart.cart);

    return (
      <div id="anteprima-carrello" className="flex-wrap">
          {
              cart.length > 0 ? (
                  <>
                      {
                          Children.toArray(cart.map((item: itemInCart) => (
                            <div className="d-flex w-100 item">
                                <div className="d-flex img-container position-relative">
                                    {/*{item.tipoAcquisto === 'item' && <img src={item.item.immagine} alt={item.item.descrizione}/>}
                                            {item.tipoAcquisto == 'installazione' && <img src={installicon} alt="installicon"/>}*/}
                                  <img  src={`../fotoProdotti/${item.item.idProdotto}.jpg`}
                                        alt={item.item.codiceProdotto}/>
                                    {
                                        item.typeOfItem === 'installazione' &&
                                        <>
                                            <img src={etichetta} style={{position: 'absolute', height: '16px'}}/>
                                            <span style={{color: 'white', position: 'absolute', fontSize: '9px', marginLeft: '5px',marginTop:'1px'}}
                                                  className="font-weight-bold">  INSTALLAZIONE </span>
                                        </>
                                    }
                                </div>
                                <div className="content w-100">
                                    <h6
                                      className="m-0 w-100"> {/*{item.tipoAcquisto === 'installazione' && 'INSTALLAZIONE-'}*/} {item.item.marca && item.item.modello ? `${item.item.marca} - ${item.item.modello}` : item.item.descrizione}{findMisuraSelezionata(item.item) && `, ${findMisuraSelezionata(item.item)?.lunghezza}m`}</h6>
                                    <p className="mb-1 w-100">{item.item.sku}</p>
                                    <div>({item.quantita} articoli) <span><b>{convertEuro(getTotaleItemInCart(item))}</b></span>
                                    </div>
                                </div>
                            </div>
                          )))
                      }
                      <div className="totale-carrello py-4 d-flex justify-content-end w-100 flex-wrap bg-disabled">
                          <div className="text-center w-100">Totale
                              ({cart.map((item) => item.quantita).reduce((tot: number, quantita) => tot + quantita, 0)} articoli): <b>{convertEuro(cart.reduce((tot: number, item: itemInCart) => tot + getTotaleItemInCart(item), 0))}</b>
                          </div>
                          <button onClick={() => history.push('carrello')} className="btn btn-viola w-100 mt-3">Vai al
                              carrello
                          </button>
                          <button onClick={() => dispatch(clearCarrello())}  className="btn btn-viola w-100 mt-3">
                                    Svuota il carrello
                          </button>
                      </div>
                  </>
                ) :
                <div className="w-100 p-4">
                    <p className="text-center m-0">Il carrello è vuoto</p>
                </div>
          }
      </div>
    )
}

export default memo(AnteprimaCarrello)
