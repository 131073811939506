import React, { useCallback, memo } from 'react'
import { getClassesWithPrefix } from '../../../../utils/utils'
import './SidebarMenu.css'

type Props = {
    isOpen: boolean
    handlerClick?: () => void
    addClasses?: string
    size?: 'xs' | 'sm' | 'lg' | '2x' | '3x' | '5x' | '7x' | '10x'
}

const menuClasses = getClassesWithPrefix('menu')

const SidebarMenu: React.FC<Props> = memo(({ isOpen, size, handlerClick, addClasses }) => {

    const toggleIsOpen = useCallback(() => {
        if (handlerClick) handlerClick()
    }, [handlerClick])

    return (
        <div
            onClick={toggleIsOpen}
            className={`${menuClasses('container')}${addClasses ? ' ' + addClasses : ''}`}
        >
            <i className={`fas fa-${isOpen ? 'times' : 'bars'} fa-${size ? size : 'sm'}`} />
        </div>
    )
})

export default SidebarMenu