import {installazioneI, itemI, itemInCart, misureI, orderI, tipiInstallazioneI} from "./model_types";

export const getClasses = (...classes: any[]) => classes.filter(Boolean).join(' ');
/**
 * @description wrapper to getClasses with a one prefix
 * how to use ex.:
 * const sideBarClasses = getClassesWithPrefix('sidebar')
 * <div className={sideBarClasses('a', 'b')}>
 *
 * @param prefix string
 */
export const getClassesWithPrefix = (prefix: string) =>
  (...classes: string[]) =>
    getClasses(...classes.map(c => `${prefix}${c ? '-' + c : c}`))

export const findMisuraSelezionata = (item: itemI) => item.misure?.find((misura: misureI) => misura.selected);
export const findInstallazioneSelezionata = (item: itemI) => item.installazioni?.find((installazione: installazioneI) => installazione.selected);
export const findTipoInstallazioneSelezionata = (tipiInstallazione: any) => tipiInstallazione.find((el: any) => el.selected);

export const getTotaleItemInCart = (item: itemInCart) => (item.typeOfItem === 'installazione' ? 0 : getCostoUnitarioValue(item.item, item.quantita)) + (item.conInstallazione ? getCostoInstallazione(item.item) * item.quantita : 0) + (item.installazioneSelected && item.item.idProdotto !== 'L2-081' ? getCostoInstallazioneSelected(item.item) * item.quantita : 0) +
  (item.item.idProdotto === 'L2-081' && item.installazioneSelected ? getCostoInstallazioneTipiInstallazione(item.tipiInstallazione, item.quantita) : 0);

export function getCostoUnitarioValue(item: itemI, quantita: number = 1) {
    //@ts-ignore
    return ((item && item.misure && item.misure.length > 0 ?
      item.misure?.find((misura: misureI) => misura.selected) ?
        (item.costoFissoItem || 0) + (item.misure?.find((misura: misureI) => misura.selected)?.lunghezza || 0) * (item?.costoAlMetroItem || 0) : 0
      : item?.costoFissoItem)) * quantita
}

export function getCostoUnitarioValueFromOrder(item: itemI, misura: any, quantita: number = 1) {
    //@ts-ignore
    return ((item && misura ? (item.costoFissoItem || 0) + (misura.lunghezza || 0) * (item?.costoAlMetroItem || 0) : item?.costoFissoItem)) * quantita
}

export function getCostoInstallazione(item: itemI, quantita: number = 1) {
    return ((item.misure && item.misure.length > 0 ?
      item.misure?.find((misura: misureI) => misura.selected) ?
        (item?.costoFissoInstallazione || 0) + (item.misure?.find((misura: misureI) => misura.selected)?.lunghezza || 0) * (item?.costoAlMetroInstallazione || 0) : 0
      : item?.costoFissoInstallazione || 0)) * quantita
}

export function getCostoInstallazioneSelected(item: itemI, quantita: number = 1) {
    return ((item.installazioni ?
      item.installazioni?.find((installazione: installazioneI) => installazione.selected) ?
        (item.installazioni?.find((installazione: installazioneI) => installazione.selected)?.costo || 0) : 0
      : 0)) * quantita
}

export function getCostoInstallazioneTipiInstallazione(tipiInstallazione: Array<tipiInstallazioneI>, quantita: number = 1) {
    // @ts-ignore
    return tipiInstallazione.find((el: any) => el.selected) ? (tipiInstallazione.find((el: any) => el.selected).costoTipoinstallazione * quantita) : 0;
}


export const scrollTop = () => {
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, 200);
}

export const scrollTopElement = (elementToScroll: string = '') => {
    setTimeout(() => {
        document.querySelector(elementToScroll)?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, 200);
}
export const dateFormat = 'DD/MM/YYYY';
export const STATO_ORDINE_SOSPESO = 'In attesa di emissione';
export const STATO_ORDINE_IN_CONSEGNA = 'In consegna';
export const STATO_ORDINE_CONSEGNATO = 'Consegnato il';
export const STATO_ORDINE_RIFIUTATO = 'Rifiutato il';
export const STATO_ORDINE_ATTESA_RIFIUTO = 'In attesa di conferma rifiuto';
export const getStatoOrdine = (ordine: orderI) => {
    if (!ordine.dataEmissione) return STATO_ORDINE_SOSPESO
}

export const breakPointers = {
    tablet: 1024,
    phone: 600
}
