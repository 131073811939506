import { useEffect } from 'react';
import { useState } from 'react';

const useResize = () => {
    const [currentSizes, setCurrentSizes] = useState({innerWidth: window.innerWidth, innerHeight: window.innerHeight})

    useEffect(() => {
        const updateSize = () => {
            const {innerHeight, innerWidth} = window
            setCurrentSizes({innerHeight, innerWidth})
        }

        window.addEventListener('resize', updateSize)

        return () => window.removeEventListener('resize', updateSize)
    }, [])


    return currentSizes
}

export default useResize