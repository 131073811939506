import {AppBar, makeStyles, Toolbar, Typography} from "@material-ui/core"
import {ExitToApp} from "@material-ui/icons"
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import IconButton from '@material-ui/core/IconButton';

import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/actionCreators/auth";
import logo from "../../assets/img/Grouplogo.svg";
import {RootState} from "../../store/reducers";
import {itemInCart} from "../../utils/model_types";
import AnteprimaCarrello from "./AnteprimaCarrello/AnteprimaCarrello";
import {useHistory} from "react-router-dom";
import {breakPointers, scrollTop} from "../../utils/utils";
import {eventBusActionOpenCloseMenu, eventBusAddRemoveOpenCloseMenu} from "../../utils/event-bus/event-bus.actions";
import useResize from "../Sidebar/hooks/useResize";
import {SidebarMenu} from "../Sidebar";
import {getRoles} from "../../routing/utils";

const menuButtonWidth = 64

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        width: `calc(100% - ${menuButtonWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        color: 'var(--giallo)',
        backgroundColor: 'white',
        fontWeight: 'bold',
        width: `calc(100%)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginRight: 10,
        color: 'var(--viola)'
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        marginLeft: '15px',
        flexGrow: 1,
        color: 'var(--giallo)'
    },
    exitMenu: {
        color: 'var(--viola)'
    },
    cartMenu: {
        color: 'var(--viola)',
        position: 'relative',
        '&:hover #anteprima-carrello': {
            display: 'flex'
        }
    },
    badge: {
        position: 'absolute',
        fontSize: '12px',
        display: 'inline-block',
        width: '20px',
        height: '20px',
        border: '1px solid var(--viola)',
        borderRadius: ' 50%',
        top: '5px',
        right: '6px',
        backgroundColor: 'navajowhite',
        fontWeight: 'bold'
    }
}));

const portalTitle = 'Catalogo elettronico';

const Header = () => {
    const {innerWidth} = useResize()
    const [isOpenMenu, setIsOpenMenu] = useState(!(breakPointers.tablet >= innerWidth))
    const dispatch = useDispatch();
    const cart = useSelector((state: RootState) => state.cart.cart)
    const classes = useStyles();
    const history = useHistory();
    const storeUser = useSelector((state: RootState) => state.auth.user);
    const role = getRoles(storeUser) || [];

    useEffect(() => {
        history.listen((location) => {
            const {pathname} = location;
            if (!pathname.includes('login')) localStorage.setItem('path', pathname);
            scrollTop();
        });
    }, []);

    useEffect(() => {
        const evtOpenCloseMenu = eventBusAddRemoveOpenCloseMenu(setIsOpenMenu)

        return () => {
            evtOpenCloseMenu.remove()
        }
    }, [isOpenMenu])

    const handleLogout = () => {
        dispatch(logout())
        history.push('/login')
    };

    // @ts-ignore
    return (
      <AppBar color="default" position="absolute" className={'app-header'}>
          <Toolbar className={classes.toolbar}>
              <SidebarMenu
                handlerClick={() => eventBusActionOpenCloseMenu(!isOpenMenu)}
                isOpen={isOpenMenu}
              />
              <img src={logo} width="35px" alt="Logo Catalogo Elettronico"/>
              <Typography component="h1" variant="h6" noWrap className={classes.title}>
                  {portalTitle}
              </Typography>

               <IconButton className={classes.exitMenu} aria-label="logout" onClick={handleLogout}>
                  <ExitToApp/>
              </IconButton>

              {storeUser && !storeUser.firstAccess && role.includes("US") && <IconButton className={classes.cartMenu} aria-label="logout">
                  {cart.length > 0 && <span
                    className={classes.badge}>{
                      // @ts-ignore
                      cart.map((c: itemInCart) => parseInt(c.quantita)).reduce((tot, a) => tot + a, 0)}</span>}
                  <ShoppingCartOutlinedIcon/>
                  <AnteprimaCarrello/>
              </IconButton>}
          </Toolbar>
      </AppBar>
    )
};

export default Header;
