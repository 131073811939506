import {
    TOGGLE_LOADING,
    TOGGLE_MENU,
    SELECTED_PAGE,
} from '../actions/general';
import { GeneralAction } from '../actions/types';

export interface GeneralState {
    loading: boolean
    menu: boolean,
    selectedPage: string | undefined,
    forgotPassword: boolean
}

const initialState : GeneralState = {
    loading: false,
    menu: false,
    selectedPage: '',
    forgotPassword: false
};

const generalReducer = (state = initialState, action : GeneralAction) => {
    switch (action.type) {
        case TOGGLE_LOADING: {
            return {
                ...state,
                loading: !state.loading
            }
        }
        case TOGGLE_MENU: {
            return {
                ...state,
                menu: !state.menu
            }
        }
        case SELECTED_PAGE: {
            return {
                ...state,
                selectedPage: action.payload.message
            }
        }

        default:
            return state;
    }
};

export default generalReducer;
