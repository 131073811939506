import React, { useCallback, memo } from 'react'
import { IRoute } from '../../../../routing/types'
import { getClassesWithPrefix } from '../../../../utils/utils'

type Props = {
    route: IRoute
    isSelected: boolean
    handlerClick?: (route: IRoute) => void
}

const listItemClasses = getClassesWithPrefix('list-item')

const SidebarListItem: React.FC<Props> = memo(({ route, handlerClick, isSelected }) => {
   
    const onClickHandler = useCallback(() => {
        if (handlerClick) handlerClick(route)
    }, [route, handlerClick])

    return (
        <li
            onClick={onClickHandler}
            title={route.name}
            key={"sidebar_" + route.name}
            className={listItemClasses(isSelected ? 'selected' : '')}
        >
            <span className={listItemClasses('icon')}>
                {route.logo.icon && React.createElement(route.logo.icon, { style: { width: '.8em' } })}
            </span>
            <span className={listItemClasses('text')}>{route.name}</span>
        </li>
    )
})

export default SidebarListItem