import axios, { AxiosResponse } from 'axios';

const { REACT_APP_BASENAME } = process.env;
const TIMEOUT = 60 * 1000;

const axiosInstance = axios.create({
    timeout: TIMEOUT,
    baseURL: REACT_APP_BASENAME
});

axiosInstance.interceptors.request.use(
    config => {

        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        return config;
    },
    error => {
        Promise.reject(error)
    }
);

const errorHandler = (error: any) => {
    const { status, data: { message } } = error.response;
    let messaggio;

    switch (status) {
        case 401: {
            messaggio = 'Non sei autorizzato. Controlla le tue credenziali';
            break;
        }
        default: messaggio = message ? message : 'Errore generico!'

    }

    return Promise.reject({ messaggio })
};

const successHandler = (response: AxiosResponse) => {
    return response
};

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

export default axiosInstance;
