import React, {useState} from "react";
import {toggleSpinner, toggleToast} from "../../store/actionCreators/general";
import {useDispatch, useSelector} from "react-redux";
import axiosInstance from "../../api/interceptor";
import {RootState} from "../../store/reducers";
import {logout} from "../../store/actionCreators/auth";

const ResetPassword = () => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const storeUser = useSelector((state: RootState) => state.auth.user)



  const changePassword = () => {
    dispatch(toggleSpinner());

    if(password !== confirmPassword){
      dispatch(toggleSpinner());
      dispatch(toggleToast("error", "Le due password non combaciano"))
    } else {
      // @ts-ignore
     storeUser && axiosInstance.put(`auth/confermaPassword/${storeUser.sub}`,{password}).then(response => {
       dispatch(toggleToast("success", "La password è stata cambiata correttamente"))
       dispatch(logout());
     }).catch(error => toggleToast("error", error.messaggio) )
       .finally(() => dispatch(toggleSpinner()))
    }
  };

  return (
    <div className="resetPassword w-100">
      <div className="form">
        <h1 className="color-viola text-center font-weight-bold mt-3 mb-0">Reset password</h1>
        <form className="mt-4">
          <div className="form-group">
            <label htmlFor="username">Password</label>
            <input type="password"
                   className="form-control form-control-sm"
                   value={password}
                   onChange={e => setPassword(e.target.value)}
                   id="password"
                   placeholder="Password"
                   aria-describedby="password"/>
            {/*<small id="emailHelp" className="form-text text-danger">We'll never share your email with anyone else.</small>*/}
          </div>
          <div className="form-group">
            <label htmlFor="password">Conferma password</label>
            <input type="password"
                   className="form-control form-control-sm"
                   placeholder="Conferma password"
                   id="confirmpassword"
                   value={confirmPassword}
                   onChange={e => setConfirmPassword(e.target.value)}/>
          </div>
          <button type="button"
                  onClick={changePassword}
                  className="btn btn-block btn-viola mt-4"
                  disabled={!password || !confirmPassword}>Conferma</button>
        </form>
      </div>
    </div>
  )
};

export default ResetPassword
