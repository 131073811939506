import { IPagination } from "../../utils/model_types";

export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_TOAST = 'TOGGLE_TOAST';

export const PRICE_DESCRIPTION = {
    price: 'price',
    discountPrice: 'discountPrice'
};

export const ONHOLD= 'ONHOLD';
export const ACCEPTED= 'ACCEPTED';
export const REJECTED= 'REJECTED';

export const ORDER_SENT_SUCCESS = 'Ordini inviato correttamente';
export const ORDER_SAVED_SUCCESS = 'Ordini salvato correttamente';
export const ORDER_SENT_ERROR = 'Si è verificato un errore nell\'invio dell\'ordine';
export const ORDER_SAVED_ERROR = 'Si è verificato un errore nel salvataggio dell\'ordine';
export const ORDER_PUT_ERROR = 'Ops... Qualcosa è andato storto durante la modifica dell\'ordine.';
export const ORDER_MAIL_ERROR = 'Si è verificato un errore nell\' invio dell\' email';

export const ORDER_LIST_ERROR = 'Si è verificato un errore recuperando la lista degli ordini';
export const PRDOUCT_LIST_ERROR = 'Si è verificato un errore recuperando la lista dei prodotti';

export const PAGE_REGEX = /page=(\d*?)&/;
export const PAGE_SIZE_REGEX = /&size=(\d*?)>/;

export const US = 'US';
export const USP = 'USP';
export const UT = 'UT';

export const ROWS_PER_PAGE = [10, 20, 50];

export const DETTAGLIO_ORDINE_PATH = 'dettaglio-ordine';
export const DETTAGLIO_ORDINE_SIMULATO_PATH = 'dettaglio-ordine-simulato';


export const ORDER_ACCEPT_SUCCESS = 'Ordini accettato';
export const ORDER_REJECT_SUCCESS = 'Ordini rifiutato';
export const PO_UPLOAD_SUCCESS = 'Piano operativo caricato correttamente';
export const PO_UPLOAD_ERROR = 'Ops... Qualcosa è andato storto durante il caricamento del piano operativo.';
export const PO_DOWNLOAD_ERROR = 'Ops... Qualcosa è andato storto durante il download del piano operativo';
export const EMAIL_SENT = 'E\' stata inviata una email al corretto destinatario';

export const STEP_ACCEPTED = 'accepted';
export const STEP_REJECTED = 'rejected';
export const STEP_CREATED = 'created';
export const STEP_LOADED = 'loaded';
export const STEP_MODIFIED = 'modified';

export const updatePagination = (pagination: IPagination, paginationHeaderLink: string[], totalCount: string) => {
    const paginationUpdated = {...pagination};

    paginationHeaderLink.forEach((link: string) => {
        /**
         * considero il link "next" perchè mi indica la pagina successiva lato server, che corrisponde alla pagina reale frontend.
         * Non è necessario aggiungere un +1 alla page
         */
        if(link.includes('next')){
            const page = link.match(PAGE_REGEX);
            const pageSize = link.match(PAGE_SIZE_REGEX);
            paginationUpdated.page = page ? Number(page[1]) : 0;
            paginationUpdated.pageSize = pageSize ? Number(pageSize[1]) : 0;
        } else if (link.includes('last')) {
            const page = link.match(PAGE_REGEX);
            paginationUpdated.pageCount = page ? Number(page[1]) : 0;
        }
        paginationUpdated.rowCount = Number(totalCount) || 0;
    });
    return paginationUpdated;
};

export const SELECTED_PAGE = 'SELECTED_PAGE';

