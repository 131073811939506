import {Suspense} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {routes} from './routes';
import {IRoute, Roles, User} from './types';
import {USP, UT} from "../store/actions/general";
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

function CustomComponent(Component: any) {
    const history = useHistory();
    return function (props: any) {
        return (
          <>
              {history.length > 1 &&
              <span className="back-icon cursor-pointer" onClick={() => history.goBack()}><KeyboardBackspaceIcon/>Indietro</span>}
              <Component {...props}/>
          </>
        );
    }
}

export const getRoles = (user: User) => {
    /*
        return parseJwt(user?.access_token || '')?.resource_access?.backend?.roles;
    */
    // @ts-ignore
    return user?.ruoliUtente || [];
};

const getVisibleToAllRoutes = () => routes.filter(r => r.access.includes(0));
const getPublicRoutes = () => routes.filter(r => r.access.includes(1));
const getPrivateRoutes = () => routes.filter(r => r.access.includes(2));
const getPrivateRoutesWithRoles = (authorities: Roles) => routes.filter(r => r.access.includes(3) &&
  authorities &&
  r.roles.some(r => authorities.indexOf(r) >= 0));

// const getRouteComponent = (routes: IRoute[]) => routes.map((r, i) => <Route path={r.path} exact key={i}>{CustomComponent(r.component)}</Route>);
const getRouteComponent = (routes: IRoute[]) => routes.map((r, i) => <Route path={r.path} exact key={i}
                                                                            component={r.component}/>);

export const getRouteItems = (user: User) =>
  user ?
    getVisibleToAllRoutes().concat(getPrivateRoutesWithRoles(getRoles(user)), getPrivateRoutes()) :
    getVisibleToAllRoutes().concat(getPublicRoutes());

export const getRoutes = (user: User) => {
  // @ts-ignore
    const to =  user && user.firstAccess ? '/resetPassword' : [USP,UT].some((role: string) => user && user.ruoliUtente?.includes(role)) ? '/elenco-ordini' : 'catalogo';
    return (
      <Suspense fallback={< div> Loading...</div>}>
          <Switch>
              {getRouteComponent(getRouteItems(user))}
              <Redirect to={user ? localStorage.getItem('path') || to : '/login'}/>
          </Switch>
      </Suspense>
    )
};

export const convertEuro = (num: number) => {
    return num ? new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    }).format(num) : new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(0)
};
