type Listeners = {
    [key: string]: Array<Function>
}

const EventBus = () => {
    const listeners: Listeners = {};

    return {
        action: (type: string, payload: any) => new CustomEvent(type, { detail: payload }),
        add: function (type: any, callback: (arg?: any) => void) {
            if (!(type in listeners)) {
                listeners[type] = [];
            }
            listeners[type].push(callback);
        },
        remove: function (type: string, callback: (arg?: any) => void) {
            if (!(type in listeners)) {
                return;
            }
            const stack = listeners[type];
            for (let i = 0, l = stack.length; i < l; i++) {
                if (stack[i] === callback) {
                    stack.splice(i, 1);
                    return;
                }
            }
        },
        dispatch: function (event: CustomEvent) {
            if (!(event.type in listeners)) {
                return true;
            }
            const stack = listeners[event.type].slice();

            for (let i = 0, l = stack.length; i < l; i++) {
                stack[i].call(this, event.detail);
            }
            return !event.defaultPrevented;
        }
    }
}

export default EventBus()